import { loginSuccess, logout } from "./userSlice";
import { jwtDecode } from "jwt-decode";

export const initializeUser = () => (dispatch) => {
  const token = sessionStorage.getItem("token");
  const username = sessionStorage.getItem("username");
  const usermail = sessionStorage.getItem("usermail");

  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      dispatch(logout());
    } else {
      dispatch(loginSuccess({ token, username, email: usermail }));
    }
  } else {
    dispatch(logout());
  }
};
