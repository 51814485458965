import React from "react";

const ReservationCard = (props) => {
  return (
    <div className="bg-primary/10 p-5 rounded-lg shadow-2xl grid gap-3">
      <div>
        <p>Booked By:</p>
        <p className="text-primary font-bold text-2xl">{props.user}</p>
      </div>
      <div>
        <p className="text-primary font-semibold text-xl">Table</p>
        <p>{props.table}</p>
      </div>
      <div>
        <p className="text-primary font-semibold text-xl">Establishment</p>
        <p>{props.establishment}</p>
      </div>
      <div>
        <p className="text-primary font-semibold text-xl">Date</p>
        <p>{props.date}</p>
      </div>
      <div>
        <p className="text-primary font-semibold text-xl">Order ID</p>
        <p>{props.order}</p>
      </div>
      <div className="mx-auto bg-transparent shadow-md">{props.children}</div>
    </div>
  );
};

export default ReservationCard;
