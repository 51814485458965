import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import UserProfile from "./UserProfile";
import EstablishmentProfile from "./EstablishmentProfile";
import Approvval from "./Approvval";

const Profile = () => {
  const [show, setShow] = useState(0);

  const activeForm = () => {
    if (show === 1) {
      return <EstablishmentProfile />;
    } else if (show === 2) {
      return <Approvval />;
    } else {
      return <UserProfile />;
    }
  };
  return (
    <div className="p-7 grid gap-5 items-start">
      <div className="space-y-5">
        <NavBar title={"Admin Profile"} />
      </div>
      <div className="space-y-10 lg:flex lg:items-start lg:space-y-0">
        <div className="md:flex grid justify-start gap-5 lg:flex-col basis-1/3 lg:items-start lg:border-l border-[#848484]/30">
          <button
            onClick={() => setShow(0)}
            className={
              show === 0
                ? "border-l-4 border-primary p-3 text-primary font-semibold text-left"
                : "text-[#848484] p-3 text-left"
            }
          >
            My Profile
          </button>
          <button
            onClick={() => {
              setShow(1);
            }}
            className={
              show === 1
                ? "border-l-4 border-primary p-3 text-primary font-semibold text-left"
                : "text-[#848484] p-3 text-left"
            }
          >
            Establishment Profile
          </button>
          <button
            onClick={() => {
              setShow(2);
            }}
            className={
              show === 2
                ? "border-l-4 border-primary p-3 text-primary font-semibold text-left"
                : "text-[#848484] p-3"
            }
          >
            Establishment Approval
          </button>
        </div>
        {activeForm()}
      </div>
    </div>
  );
};

export default Profile;
