import React, { useState } from "react";
import NavBar from "../../components/NavBar";
// import StaffChart from "./StaffChart";
import BookingHistory from "./BookingHistory";
import { MdCalendarMonth } from "react-icons/md";
import TicketSales from "./TicketSales";
import TicketSalesForm from "./TicketSalesForm";
import BookingHistorySummary from "./BookingHistorySummary";

const Reservations = () => {
  const [show, setShow] = useState(2);

  const activeForm = () => {
    if (show === 1) {
      return <TicketSales />;
    } else if (show === 0) {
      return <BookingHistory />;
    } else {
      return <TicketSalesForm />;
    }
  };

  const date = new Date();
  //   const month = date.toLocaleDateString("en-US", { month: "numeric" });
  //   const day = date.toLocaleDateString("en-US", { day: "numeric" });
  const year = date.toDateString();
  return (
    <div className="p-7 grid gap-5 items-start">
      <div className="space-y-5">
        <NavBar title={"Reservations"} />
      </div>
      <div className="space-y-10 lg:grid lg:grid-flow-col lg:space-y-0 lg:grid-cols-4">
        <div className="flex items-center gap-5 lg:flex-col basis-1/3 lg:items-start lg:border-l border-[#848484]/30 h-fit lg:col-span-1">
          <button
            onClick={() => setShow(2)}
            className={
              show === 2
                ? "border-l-4 border-primary p-3 text-primary font-semibold"
                : "text-[#848484] p-3"
            }
          >
            Create Event
          </button>
          <button
            onClick={() => {
              setShow(1);
            }}
            className={
              show === 1
                ? "border-l-4 border-primary p-3 text-primary font-semibold"
                : "text-[#848484] p-3"
            }
          >
            Ticket Sales
          </button>
          <button
            onClick={() => {
              setShow(0);
            }}
            className={
              show === 0
                ? "border-l-4 border-primary p-3 text-primary font-semibold"
                : "text-[#848484] p-3"
            }
          >
            Booking History
          </button>
        </div>
        <div className="lg:col-span-3 space-y-10">
          {show !== 2 && (
            <div className="md:flex items-center justify-between">
              <h2 className="font-semibold text-base">Booking History</h2>
              <div className="flex gap-10">
                <h2 className="font-semibold text-base">{year}</h2>
                <h2 className="font-semibold text-base flex items-center text-primary">
                  <MdCalendarMonth />
                  Date Filter
                </h2>
              </div>
            </div>
          )}
          {activeForm()}
        </div>
      </div>
    </div>
  );
};

export default Reservations;
