import React from "react";
// import NavBar from "../../components/NavBar";
import Avatar from "../../images/Avatar.svg";
import Input from "../../components/Input";
import { Option, Select } from "@material-tailwind/react";
import PrimaryButton from "../../components/PrimaryButton";
import { useSelector } from "react-redux";

const ProfileUpdateForm = () => {
  const currentYear = new Date().getFullYear();

  const adminDetail = useSelector((state) => state.user);

  console.log(adminDetail);

  const startYear = currentYear - 80;
  const endYear = currentYear - 16;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className="p-7 grid gap-5 items-start">
      <div className="grid gap-5">
        <div className="grid justify-items-center gap-5">
          <img
            src={Avatar}
            className="h-16 w-16 bg-primary/20 rounded-full"
            alt="Profile"
          />
          <h3 className="text-xl font-semibold">
            {adminDetail?.user?.email || "Admin"}
          </h3>
        </div>
        <form action="" className="grid gap-5">
          <fieldset className="grid md:grid-flow-col gap-5">
            <Input label={"First Name"} type={"text"} id={"firstName"} />
            <Input label={"Last Name"} type={"text"} id={"lastName"} />
          </fieldset>
          <Input label={"Email"} type={"mail"} id={"email"} />
          <Input label={"Phone Number"} type={"tel"} id={"phone"} />
          <div className="bg-gradient-to-b rounded-lg relative from-[#EB7C4C] to-[#A03484] p-0.5">
            <Select label="Gender" className="bg-white border-none">
              <Option>Male</Option>
              <Option>Female</Option>
              <Option>Prefer Not to Say</Option>
            </Select>
          </div>
          <div className="bg-gradient-to-b rounded-lg relative from-[#EB7C4C] to-[#A03484] p-0.5">
            <Select label="Role" className="bg-white border-none">
              <Option>Administrator</Option>
              <Option>Security Personnel</Option>
            </Select>
          </div>
          <fieldset className="grid gap-3">
            <label htmlFor="">Date of Birth</label>
            <fieldset className="grid gap-5 md:grid-flow-col">
              <Input label={"Day"} type={"number"} id={"day"} />
              <div className="bg-gradient-to-b rounded-lg relative from-[#EB7C4C] to-[#A03484] p-0.5 basis-1/3">
                <Select label="Month" className="bg-white border-none">
                  {months.map((month, index) => (
                    <Option key={index}>{month}</Option>
                  ))}
                </Select>
              </div>
              <div className="bg-gradient-to-b rounded-lg relative from-[#EB7C4C] to-[#A03484] p-0.5 basis-1/3">
                <Select label="Year" className="bg-white border-none">
                  {Array.from(
                    { length: endYear - startYear + 1 },
                    (_, index) => (
                      <Option key={startYear + index}>
                        {startYear + index}
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </fieldset>
          </fieldset>
          <fieldset className="grid gap-3">
            <label htmlFor="">Address</label>
            <fieldset className="grid gap-5">
              <Input
                label={"Street Address"}
                type={"text"}
                id={"streetAddress"}
              />
              <fieldset className="grid md:grid-flow-col gap-5">
                <Input label={"City"} type={"text"} id={"city"} />
                <Input label={"State"} type={"text"} id={"State"} />
              </fieldset>
            </fieldset>
          </fieldset>
          <PrimaryButton text={"Submit"} />
        </form>
      </div>
    </div>
  );
};

export default ProfileUpdateForm;
