import React from "react";
import { Card, Typography } from "@material-tailwind/react";
// import { BiEdit, BiTrash } from "react-icons/bi";

const TicketSales = () => {
  const HEAD = ["Name", "Ticket", "Date"];
  const TABLE_ROWS = [
    {
      name: "Olayinka Odelana",
      job: "Ultimate 3",
      status: "Occupied",
    },
    {
      name: "Olu Isola",
      job: "VIP 4",
      status: "Occupied",
    },
    {
      name: "Rick Kings",
      job: "VIP 2",
      status: "Reserved",
    },
    {
      name: "Jonate Okafor",
      job: "Ultimate 8",
      status: "Reserved",
    },
  ];

  return (
    <>
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(({ name, job, status }, index) => (
              <tr key={name} className="even:bg-blue-gray-50/50">
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {job}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className={"font-normal"}
                  >
                    {status}
                  </Typography>
                  {/* <div className="w-max">
                    <Chip
                      size="sm"
                      variant="ghost"
                      value={status}
                      color={status === "Occupied" ? "green" : "red"}
                    />
                  </div> */}
                </td>
                {/* <td className="p-4 flex gap-5 items-center">
                  <BiEdit className="text-blue-600 cursor-pointer" />
                  <hr className="text-black w-5 rotate-90" />
                  <BiTrash className="text-red-600 cursor-pointer" />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default TicketSales;
