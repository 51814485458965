import React, { useState } from "react";
import Input from "../../components/Input";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import axiosInstance from "../../utils/axios/axios";
import { BsPatchCheck } from "react-icons/bs";
import { Alert, Option, Select, Spinner } from "@material-tailwind/react";
import { CiWarning } from "react-icons/ci";
import { BiHide, BiShowAlt } from "react-icons/bi";
import states from "../../utils/AllStates.json";
// import { setKey, fromAddress } from "react-geocode";
import axios from "axios";
import { useEffect } from "react";
// import { type } from "@testing-library/user-event/dist/type";

const SignUp = ({ isAdmin, setIsAdmin }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [state, setState] = useState("");
  const [type, setType] = useState(null);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [alert, setAlert] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [icon, setIcon] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const venueTypes = [
    {
      type: "Other",
      value: 1,
    },
    {
      type: "Club",
      value: 2,
    },
    {
      type: "Bar",
      value: 3,
    },
    {
      type: "Lounge",
      value: 4,
    },
    {
      type: "Event",
      value: 5,
    },
  ];

  useEffect(() => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      )
      .then((res) => {
        console.log(res);
        setLat(res?.data.results[0].geometry.location.lat);
        setLong(res?.data.results[0].geometry.location.lng);
      })
      .catch((err) => {
        return;
      });
  }, [address]);

  const data = !admin
    ? {
        username,
        email,
        password,
      }
    : {
        user: {
          name: name,
          email: email,
          password: password,
          confirmPassword: "string",
        },
        establishment: {
          name: username,
          longitude: long,
          latitude: lat,
          address: address,
          state: state,
          contactPhone: null,
          description: null,
          type: type,
        },
      };

  setTimeout(() => {
    if (alert === true) {
      setAlert(false);
    }
  }, 3000);

  const handleSubmit = () => {
    if (!admin) {
      setLoading(true);
      // Check if the password meets the regex pattern
      if (/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/.test(password)) {
        // Password is valid, proceed with registration
        axiosInstance
          .post("/registration", data)
          .then((res) => {
            setAlert(!alert);
            setBgColor("green");
            setIcon(<BsPatchCheck />);
            setLoading(false);
            setResponse(`${res.data.message}`);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          .catch((err) => {
            // console.log(err);
            setLoading(false);
            setAlert(!alert);
            setBgColor("red");
            setIcon(<CiWarning />);
            setResponse(
              err?.data?.message || err?.response?.data?.message || err?.message
            );
          });
      } else {
        // Password is not valid
        setLoading(false);
        setAlert(!alert);
        setBgColor("red");
        setIcon(<CiWarning />);
        setResponse(
          "Password must be 8 characters long and must include, at least, one uppercase one special character"
        );
      }
    } else {
      // Acount Creation for Admin
      console.log(data);
      setLoading(true);
      if (/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/.test(password)) {
        // Password is valid, proceed with registration
        axiosInstance
          .post("/auth/establishment/signup", data)
          .then((res) => {
            setAlert(!alert);
            setBgColor("green");
            setIcon(<BsPatchCheck />);
            setLoading(false);
            setResponse(
              `${res.data.message}. Your account will be approved within the next 48 hours.`
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          .catch((err) => {
            // console.log(err);
            setLoading(false);
            setAlert(!alert);
            setBgColor("red");
            setIcon(<CiWarning />);
            setResponse(
              err?.data?.message || err?.response?.data?.message || err?.message
            );
          });
      } else {
        // Password is not valid
        setLoading(false);
        setAlert(!alert);
        setBgColor("red");
        setIcon(<CiWarning />);
        setResponse(
          "Password must be 8 characters long and must include, at least, one uppercase one special character"
        );
      }
    }
  };

  // const handleSubmit = async () => {
  //   setLoading(true);

  //   // Function to handle the response for setting alerts and responses
  //   const handleResponse = (success, message) => {
  //     setAlert(!alert);
  //     setBgColor(success ? "green" : "red");
  //     setIcon(success ? <BsPatchCheck /> : <CiWarning />);
  //     setResponse(message);
  //     if (success) {
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     } else {
  //       setLoading(false);
  //     }
  //   };

  //   const isValidPassword =
  //     /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/.test(password);
  //   if (!isValidPassword) {
  //     handleResponse(
  //       false,
  //       "Password must be at least 8 characters long and include one uppercase letter, one special character, and one number."
  //     );
  //     return;
  //   }

  //   if (!admin) {
  //     // Non-admin registration
  //     try {
  //       const res = await axiosInstance.post("/registration", data);
  //       handleResponse(true, res.data.message);
  //     } catch (err) {
  //       handleResponse(false, err?.response?.data?.message || err.message);
  //     }
  //   } else {
  //     // Admin registration
  //     try {
  //       const geoRes = await axios.get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //           address
  //         )}&key=${API_KEY}`
  //       );
  //       if (geoRes.data.status === "OK") {
  //         const location = geoRes.data.results[0].geometry.location;
  //         console.log("location", location);
  //         setLat(location.lat);
  //         setLong(location.lng); // Corrected property name to 'lng'

  //         try {
  //           const signupRes = await axiosInstance.post(
  //             "/auth/establishment/signup",
  //             data
  //           );
  //           handleResponse(true, signupRes.data.message);
  //         } catch (signupErr) {
  //           handleResponse(
  //             false,
  //             signupErr?.response?.data?.message || signupErr.message
  //           );
  //         }
  //       } else {
  //         handleResponse(false, "Invalid address, please try again.");
  //       }
  //     } catch (geoErr) {
  //       handleResponse(false, "Invalid address, please try again.");
  //       console.log(geoErr);
  //     }
  //   }
  // };

  // State to show password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <form onSubmit={handleSubmit} className="grid gap-5 lg:w-2/3 lg:mx-auto">
      {alert && (
        <Alert
          animate={{
            mount: { y: 0 },
            unmount: { y: 0 },
          }}
          color={bgColor}
          icon={icon}
          className="absolute h-auto top-8 w-11/12 right-5 z-50"
        >
          {response}
        </Alert>
      )}
      {!admin ? (
        <>
          <Input
            autoComplete={"off"}
            label={"Email"}
            type={"email"}
            id={"email"}
            value={email}
            onChange={handleEmailChange}
          />
          <Input
            label={"Username"}
            type={"text"}
            id={"username"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </>
      ) : (
        <>
          <Input
            label={"Full Name"}
            type={"text"}
            id={"name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label={"Establishment Name"}
            type={"text"}
            id={"establishmentName"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            autoComplete={"off"}
            label={"Email"}
            type={"email"}
            id={"email"}
            value={email}
            onChange={handleEmailChange}
          />
          <Input
            autoComplete={"off"}
            label={"Address"}
            type={"text"}
            id={"address"}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Select
            label="State"
            value={data.establishment.state}
            onChange={(val) => setState(val)}
            error
            className="bg-white"
          >
            {states.map((state, index) => (
              <Option value={state} key={index}>
                {state}
              </Option>
            ))}
          </Select>
          <Select
            color="red"
            label="Type"
            value={data.establishment.type}
            onChange={(val) => setType(val)}
            error
            className="bg-white"
          >
            {venueTypes.map((type, index) => (
              <Option value={type.value} key={index}>
                {type.type}
              </Option>
            ))}
          </Select>
        </>
      )}
      <div className="relative">
        <Input
          label={"Password"}
          type={showPassword ? "text" : "password"}
          id={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {!showPassword && (
          <BiShowAlt
            className="bg-[#F9F9F9] absolute text-primary/25 h-8 top-2 right-3 w-8"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        {showPassword && (
          <BiHide
            className="bg-[#F9F9F9] absolute text-primary/25 h-8 top-2 right-3 w-8"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      <div className="relative">
        <Input
          label={"Confirm Password"}
          type={showConfirm ? "text" : "password"}
          id={"confirmPassword"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {!showConfirm && (
          <BiShowAlt
            className="bg-[#F9F9F9] absolute text-primary/25 h-8 top-2 right-3 w-8"
            onClick={() => setShowConfirm(!showConfirm)}
          />
        )}
        {showConfirm && (
          <BiHide
            className="bg-[#F9F9F9] absolute text-primary/25 h-8 top-2 right-3 w-8"
            onClick={() => setShowConfirm(!showConfirm)}
          />
        )}
      </div>
      <label className="flex items-center gap-2" htmlFor="rememberMe">
        <input
          className="h-5 w-5 rounded-md border-primary text-primary focus:ring-primary"
          type="checkbox"
          name=""
          id=""
          value={agree}
          onChange={(e) => {
            setAgree((prevAgree) => !prevAgree);
          }}
        />
        <p>
          I agree to the{" "}
          <Link className="text-primary underline underline-offset-4">
            Terms & Conditions
          </Link>{" "}
          <span>and</span>{" "}
          <Link className="text-primary underline underline-offset-4">
            Privacy Policy
          </Link>
        </p>
      </label>
      <p className="text-center text-lg">
        To sign up as {admin ? "user" : "admin"}, click{" "}
        <span
          className="text-primary cursor-pointer"
          onClick={() => {
            setIsAdmin(!isAdmin);
            setAdmin(!admin);
            // adminLogin();
          }}
        >
          Here
        </span>
      </p>
      <PrimaryButton
        disabled={
          password !== confirmPassword ||
          agree === false ||
          password === "" ||
          confirmPassword === "" ||
          // phone === "" ||
          email === ""
        }
        onClick={handleSubmit}
        text={loading ? <Spinner color="pink" /> : "Create Account"}
      />
      {!isEmailValid && (
        <p className="text-primary">Please enter a valid email address.</p>
      )}
      {password !== confirmPassword && (
        <p> Password and Confirm Password do not match. Please try again. </p>
      )}
    </form>
  );
};

export default SignUp;
