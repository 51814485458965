import React, { useState } from "react";
import { HiPhone } from "react-icons/hi";
import { PiClockLight, PiWineLight } from "react-icons/pi";
import { TfiLocationArrow, TfiStar } from "react-icons/tfi";
import Club from "../../images/Club.jpg";
import Map from "../../images/Map.svg";
import { CiEdit } from "react-icons/ci";
import ProfileForm from "./ProfileForm";

const EstablishmentProfile = () => {
  const [formVisible, setFormVisible] = useState(false);

  return (
    <>
      {!formVisible && (
        <div>
          <div className="grid gap-14">
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <h4 className="text-2xl font-bold">Checkers Club</h4>
                <CiEdit
                  className="text-primary h-6 w-6 cursor-pointer"
                  onClick={() => setFormVisible((prevVisible) => !prevVisible)} // This handles the toggle to show user profile form
                />
              </div>
              <img
                className="w-full md:h-52 lg:h-72 object-cover rounded-lg h-full"
                src={Club}
                alt="business banner"
              />
            </div>
            <div className="grid gap-2">
              <div className="flex justify-between">
                <span className="flex">
                  <PiWineLight className="h-5 w-5 text-primary" />
                  <p>Club</p>
                </span>
                <span className="flex">
                  <TfiLocationArrow className="h-5 w-5 rotate-90 text-primary" />
                  <p>2.6Km</p>
                </span>
                <span className="flex">
                  <TfiStar className="h-5 w-5 text-primary" />
                  <p>4.1(64)</p>
                </span>
                <span className="flex">
                  <PiClockLight className="h-5 w-5 text-primary" />
                  <p>09:00pm</p>
                  {/* <p>{TimeConverter(summary?.openingTime)}</p> */}
                </span>
              </div>
              <span className="flex">
                <HiPhone className="h-5 w-5 text-primary" />
                <p className="font-semibold">08102403238</p>
              </span>
            </div>
          </div>
          <div className="space-y-5">
            <div>
              <h3 className="text-xl font-semibold">Location</h3>
              <p>26, Lekki beach road, Lekki Phase 1, Lagos Nigeria</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold">About</h3>
              <p>
                Welcome to our vibrant and electrifying nightclub, where the
                night comes alive and unforgettable experiences await you at
                every turn. We pride ourselves on creating an atmosphere that
                ignites the senses, offering a unique fusion of music, dance,
                and entertainment that will leave you craving for more.
              </p>
            </div>
            <div className="space-y-5">
              <h3 className="text-xl font-semibold">Table Information</h3>
              <div className="bg-primary/20 p-5 rounded-lg space-y-5 md:flex">
                <div className="space-y-2 md:basis-1/2">
                  <img src={Map} alt="" />
                  <h3 className="font-semibold">Ultimate Table</h3>
                </div>
                <div className="space-y-5 md:basis-1/2">
                  <h3 className="font-semibold">Table Details</h3>
                  <p>
                    Welcome to our vibrant and electrifying nightclub, where the
                    night comes alive and unforgettable experiences await you at
                    every turn. We pride ourselves on creating an atmosphere
                    that ignites the senses, offering a unique fusion of music,
                    dance, and entertainment that will leave you craving for
                    more.
                  </p>
                  <div className="space-y-10">
                    <div>
                      <p className="text-[#848484]">Ticket Amount</p>
                      <p className="font-semibold">₦ 1, 000, 000.00</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-[#848484]">Available Tables</p>
                        <p className="font-semibold">10</p>
                      </div>
                      <div className="text-right">
                        <p className="text-[#848484]">Guest(s) Allowed</p>
                        <p className="font-semibold">7</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {formVisible && <ProfileForm />}
    </>
  );
};

export default EstablishmentProfile;
