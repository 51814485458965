import { Card, Chip, Typography } from "@material-tailwind/react";
import React from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { IoSearchOutline } from "react-icons/io5";

const Approvval = () => {
  const HEAD = ["Establishment", "Approval Date", "Approval Status", "Actions"];

  const TABLE_ROWS = [
    {
      name: "Checkers Club",
      date: "25/04/2024",
      status: "Approved",
    },
    {
      name: "Dustin’ Palace",
      date: "25/04/2024",
      status: "Approved",
    },
    {
      name: "Rick Kings",
      date: "25/04/2024",
      status: "Declined",
    },
    {
      name: "Jonate Okafor",
      date: "25/04/2024",
      status: "Approved",
    },
  ];

  return (
    <div className="space-y-10 grid basis-2/3">
      <div className="grid gap-5 md:flex md:items-center md:justify-between">
        <h4 className="text-2xl leading-[30.72px] font-semibold">
          Establishment Approval
        </h4>
        <div className="flex items-center relative border border-[#A79D9D] rounded-full pl-4 pr-6 bg-transparent md:w-1/2">
          <input
            type="search"
            name="search"
            id="search"
            placeholder="SEARCH ESTABLISHMENT"
            className="placeholder:text-[#C4C4C4] w-full outline-none focus:ring-0 focus:outline-none  bg-transparent ring-0 leading-6 border-none"
          />
          <IoSearchOutline className="h-[19px] w-[19px] absolute right-4 text-[#37474F]" />
        </div>
      </div>
      {/* <div className="w-full"> */}
      <Card className="h-full overflow-y-auto">
        <table className="w-full min-w-max table-auto text-left overflow-y-auto">
          <thead>
            <tr>
              {HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(({ name, date, status }, index) => (
              <tr key={name} className="even:bg-blue-gray-50/50">
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {date}
                  </Typography>
                </td>
                <td className="p-4">
                  <div className="w-max">
                    <Chip
                      size="sm"
                      variant="ghost"
                      value={status}
                      color={status === "Approved" ? "green" : "red"}
                    />
                  </div>
                </td>
                <td className="p-4 flex gap-5 items-center">
                  <BiEdit className="text-blue-600 cursor-pointer" />
                  <hr className="text-black w-5 rotate-90" />
                  <BiTrash className="text-red-600 cursor-pointer" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      {/* </div> */}
    </div>
  );
};

export default Approvval;
