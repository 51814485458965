import React from "react";
import NavigationItem from "./NavigationItem";
import { FaCompass, FaUser } from "react-icons/fa";
import { IoIosHome } from "react-icons/io";
import { GoHistory } from "react-icons/go";
// import { FiPower } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsAuthenticated } from "../utils/app/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { MdLogin, MdOutlineQrCodeScanner } from "react-icons/md";
import { LiaUsersCogSolid } from "react-icons/lia";

const Menu = (props) => {
  // Check if token exists
  // const token = sessionStorage.getItem("token");

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const userLevel = useSelector((state) => state?.user?.user?.userType);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userLogOut = () => {
    dispatch(logout());
    navigate("/");
  };

  const userMenu = () => {
    if (!isAuthenticated || !userLevel) {
      return (
        <>
          <NavigationItem link={"/"}>
            <FaCompass className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Explore</p>
          </NavigationItem>
          <Link
            className="md:hidden p-4 flex gap-3 items-center text-[#848484]"
            to={"/join"}
          >
            <MdLogin className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Sign In/Sign Up</p>
          </Link>
        </>
      );
    } else if (isAuthenticated && userLevel === "Patron") {
      return (
        <>
          <NavigationItem link={"/"}>
            <FaCompass className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Explore</p>
          </NavigationItem>
          <NavigationItem link={"/dashboard"}>
            <IoIosHome className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Dashboard</p>
          </NavigationItem>
          <NavigationItem link={"/history"}>
            <GoHistory className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Reservations</p>
          </NavigationItem>
          <NavigationItem link={"/profile"}>
            <FaUser className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Profile</p>
          </NavigationItem>
          <div
            className="cursor-pointer p-4 hidden lg:block gap-3 items-center text-[#848484]"
            onClick={userLogOut}
          >
            <p>Logout</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <NavigationItem link={"/dashboard"}>
            <IoIosHome className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Dashboard</p>
          </NavigationItem>
          <NavigationItem link={"/staff"}>
            <LiaUsersCogSolid className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Staff</p>
          </NavigationItem>
          {/* <NavigationItem link={"/reservations"}>
            <GoHistory className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Reservations</p>
          </NavigationItem> */}
          {/* <NavigationItem link={"/scan"}>
            <MdOutlineQrCodeScanner className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Scan</p>
          </NavigationItem> */}
          <NavigationItem link={"/adminProfileForm"}>
            <FaUser className="h-6 w-6 p-[2px] lg:hidden" />
            <p>Profile</p>
          </NavigationItem>
          <div
            className="cursor-pointer p-4 hidden lg:block flex gap-3 items-center text-[#848484]"
            onClick={userLogOut}
          >
            <p>Logout</p>
          </div>
        </>
      );
    }
  };

  return (
    <div className="grid gap-4 lg:grid-flow-col relative">{userMenu()}</div>
  );
};

export default Menu;
