import React from "react";
import Input from "../../components/Input";
import {
  //   Button,
  //   ButtonGroup,
  Option,
  Select,
  //   Step,
  //   Stepper,
} from "@material-tailwind/react";
import { BiCloudUpload } from "react-icons/bi";

const BasicInformation = ({ formData, setFormData, handleChange }) => {
  const handleSelect = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      establishmentDetails: {
        ...prevFormData.establishmentDetails,
        establishmentType: val,
      },
    }));
  };

  const handleUpload = (e) => {
    const files = e.currentTarget.files;
    if (!files || files.length === 0) return;

    Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(file);
        });
      })
    ).then((results) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        establishmentDetails: {
          ...prevFormData.establishmentDetails,
          base64Images: [
            ...prevFormData.establishmentDetails.base64Images,
            ...results,
          ],
        },
      }));
    });
  };

  return (
    <>
      <fieldset className="grid gap-5 md:grid-cols-2">
        <Input
          label={"Name"}
          type={"text"}
          name={"name"}
          id={"name"}
          onChange={handleChange}
        />
        <div className="bg-gradient-to-b rounded-lg relative from-[#EB7C4C] to-[#A03484] p-0.5">
          <Select
            value={formData.establishmentDetails.establishmentType}
            label="Type"
            // name="establishmentType"
            className="bg-white border-none"
            onChange={handleSelect}
          >
            <Option value="1">Bar</Option>
            <Option value="2">Club</Option>
            <Option value="3">Lounge</Option>
            <Option value="4">Strip Club</Option>
            <Option value="5">Pub</Option>
          </Select>
        </div>
        <Input
          label={"Phone Number"}
          type={"tel"}
          id={"contactPhone"}
          name={"contactPhone"}
          onChange={handleChange}
        />
        <Input
          label={"Opening Time"}
          type={"time"}
          id={"openingTime"}
          name={"openingTime"}
          step="1"
          // value="00:00"
          onChange={handleChange}
        />
        <Input
          label={"Closing Time"}
          type={"time"}
          step="1"
          id={"closingTime"}
          name={"closingTime"}
          // value="00:00"
          onChange={handleChange}
        />
        <Input
          label={"Location"}
          type={"text"}
          id={"address"}
          name={"address"}
          onChange={handleChange}
        />
      </fieldset>
      {formData.establishmentDetails.base64Images.length === 0 ? (
        <label htmlFor="fileUpload">
          <div className="flex items-center justify-center w-full border-2 border-dashed rounded-lg cursor-pointer">
            <div className="flex flex-col items-center justify-center py-10">
              <p>Display Picture</p>
              <BiCloudUpload />
              <p>
                Drag and Drop or <span className="text-primary">Browse</span> to
                upload
              </p>
              <input
                type="file"
                name="fileUpload"
                id="fileUpload"
                onChange={handleUpload}
                multiple
                className="sr-only"
              />
            </div>
          </div>
        </label>
      ) : (
        <div className="grid grid-flow-col grid-cols-3 gap-3items-center justify-center w-full border-2 border-dashed rounded-lg cursor-pointer">
          {formData.establishmentDetails.base64Images.map((imgSrc, index) => (
            <img
              key={index}
              src={imgSrc}
              alt={`Upload ${index}`}
              className="object-contain h-20 w-20"
            />
          ))}
        </div>
      )}
      <Input
        label={"Description"}
        type={"text"}
        name={"description"}
        id={"description"}
        onChange={handleChange}
      />
    </>
  );
};

export default BasicInformation;
