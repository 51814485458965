import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import ProfileUpdateForm from "./ProfileUpdateForm";
import StaffChart from "./StaffChart";

const Staff = () => {
  const [show, setShow] = useState(0);

  const activeForm = () => {
    if (show === 1) {
      return <StaffChart />;
    } else {
      return <ProfileUpdateForm />;
    }
  };
  return (
    <div className="p-7 grid gap-5 items-start">
      <div className="space-y-5">
        <NavBar title={"Staff Profile"} />
      </div>
      <div className="space-y-10 lg:flex lg:items-start lg:space-y-0">
        <div className="flex items-center gap-5 lg:flex-col basis-1/3 lg:items-start lg:border-l border-[#848484]/30">
          <button
            onClick={() => setShow(0)}
            className={
              show === 0
                ? "border-l-4 border-primary p-3 text-primary font-semibold"
                : "text-[#848484] p-3"
            }
          >
            Staff Profile
          </button>
          <button
            onClick={() => {
              setShow(1);
            }}
            className={
              show === 1
                ? "border-l-4 border-primary p-3 text-primary font-semibold"
                : "text-[#848484] p-3"
            }
          >
            Staff Chart
          </button>
        </div>
        {activeForm()}
      </div>
    </div>
  );
};

export default Staff;
