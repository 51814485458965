import React from "react";
import Input from "../../components/Input";
import { BiCloudUpload } from "react-icons/bi";

const TableInformation = ({ formData, setFormData }) => {
  const handleTableInputChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const updatedTables = [...prevFormData.tables];
      updatedTables[index][field] = value;
      return {
        ...prevFormData,
        tables: updatedTables,
      };
    });
  };

  const handleUpload = (e) => {
    const files = e.currentTarget.files;
    if (!files || files.length === 0) return;

    Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(file);
        });
      })
    ).then((results) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        establishmentDetails: {
          ...prevFormData.establishmentDetails,
          base64Images: [
            ...prevFormData.establishmentDetails.base64Images,
            ...results,
          ],
        },
      }));
    });
  };

  return (
    <>
      {formData.tables.map((table, index) => (
        <div className="grid gap-5" key={index}>
          <fieldset className="grid gap-5 md:grid-cols-2">
            <Input
              label={"Table Name/number"}
              type={"text"}
              id={`tableName_${index}`}
              name={`tableName_${index}`}
              onChange={(e) =>
                handleTableInputChange(index, "name", e.target.value)
              }
            />
            <Input
              label={"Available Tables"}
              type={"number"}
              id={`numberOfTables_${index}`}
              name={`numberOfTables_${index}`}
              onChange={(e) =>
                handleTableInputChange(index, "numberOfTables", +e.target.value)
              }
            />
            <Input
              label={"Guests Allowed"}
              type={"number"}
              id={`numberOfSeat_${index}`}
              name={`numberOfSeat_${index}`}
              onChange={(e) =>
                handleTableInputChange(index, "numberOfSeat", +e.target.value)
              }
            />
            <Input
              label={"Table Price"}
              type={"number"}
              id={`price_${index}`}
              name={`price_${index}`}
              onChange={(e) =>
                handleTableInputChange(index, "price", +e.target.value)
              }
            />
          </fieldset>
          <Input
            label={"Table Details"}
            type={"text"}
            id={`description_{$index}`}
            name={`description_{$index}`}
            onChange={(e) =>
              handleTableInputChange(index, "description", e.target.value)
            }
          />
          <label htmlFor="fileUpload">
            <div className="flex items-center justify-center w-full border-2 border-dashed rounded-lg cursor-pointer">
              <div className="flex flex-col items-center justify-center py-10">
                <p>Table Location Plan</p>
                <BiCloudUpload />
                <p>
                  Drag and Drop or <span className="text-primary">Browse</span>{" "}
                  to upload
                </p>
                <input
                  type="file"
                  name="fileUpload"
                  id="fileUpload"
                  onChange={handleUpload}
                  className="sr-only"
                />
              </div>
            </div>
          </label>
        </div>
      ))}
    </>
  );
};

export default TableInformation;
