import { useState } from "react";
import Input from "../../components/Input";
import PrimaryButton from "../../components/PrimaryButton";
import Jaiye from "../../images/Jaiye.svg";
import { Alert, Spinner } from "@material-tailwind/react";
import axiosInstance from "../../utils/axios/axios";
import { BsPatchCheck } from "react-icons/bs";
import { CiWarning } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { login } from "../../utils/app/userSlice";
import { useNavigate } from "react-router-dom";

const SuperAdminLogin = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [alert, setAlert] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [icon, setIcon] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  setTimeout(() => {
    if (alert === true) {
      setAlert(false);
    }
  }, 3000);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (data.email && data.password) {
      console.log(data);
      setLoading(true);
      axiosInstance
        .post("/admin-login", data)
        .then((res) => {
          console.log(res);
          setAlert(!alert);
          setBgColor("green");
          setIcon(<BsPatchCheck />);
          setLoading(false);
          setResponse(res.data.message + ". Redirecting!");
          dispatch(login(res.data));
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);
          setAlert(!alert);
          setBgColor("red");
          setIcon(<CiWarning />);
          setResponse(err.response.data.message);
        });
    } else {
      setResponse("Enter value into all fields");
    }
  };

  return (
    <div className="min-h-screen w-full bg-primary/20 grid content-center p-7">
      {alert && (
        <Alert
          animate={{
            mount: { y: 0 },
            unmount: { y: 0 },
          }}
          color={bgColor}
          icon={icon}
          className="absolute h-auto top-8 w-11/12 right-5 z-50"
        >
          {response}
        </Alert>
      )}
      <div className="w-full py-5 bg-[#F9F9F9] grid px-3 gap-5 md:w-1/2 lg:w-1/3 shadow-xl mx-auto rounded-md">
        <img src={Jaiye} alt="logo" className="mx-auto h-16" />
        <h1 className="text-center text-4xl font-extrabold">Jaiye</h1>
        <Input
          label={"Email Address"}
          type={"mail"}
          // name={"email"}
          value={data.email}
          onChange={(e) => {
            setData({ ...data, email: e.target.value });
          }}
        />
        <Input
          label={"Password"}
          type={"password"}
          // name={"password"}
          value={data.password}
          onChange={(e) => {
            setData({ ...data, password: e.target.value });
          }}
        />
        {/* <PrimaryButton text={"Login"} onClick={handleSubmit} /> */}
        <PrimaryButton
          onClick={handleSubmit}
          text={loading ? <Spinner color="pink" /> : "Login"}
        />
      </div>
    </div>
  );
};

export default SuperAdminLogin;
