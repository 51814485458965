import React, { useEffect, useState } from "react";
import {
  PiCalendarLight,
  PiClockLight,
  PiUsersLight,
  PiWineLight,
} from "react-icons/pi";
import { TfiLocationArrow, TfiStar } from "react-icons/tfi";
import { HiPhone } from "react-icons/hi";
import {
  Alert,
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { MdOutlineTableBar } from "react-icons/md";
import { BsPatchCheck, BsTicketPerforated } from "react-icons/bs";
import PageTitle from "../utils/PageTitle";
import Accordion from "../components/Accordion";
import TimePicker from "../components/Timpicker";
import Tablepicker from "../components/Tablepicker";
import BarMap from "../images/Map.svg";
import NavBar from "../components/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
// import TimeConverter from "../components/TimeConverter";
import { Map, Marker } from "pigeon-maps";
import axiosInstance from "../utils/axios/axios";
import { CiWarning } from "react-icons/ci";
import PaystackPop from "@paystack/inline-js";
import DrinkPicker from "../components/DrinkPicker";
import { DayPicker } from "react-day-picker";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const Clubpage = () => {
  // State to manage steps to book seats
  const [steps, setSteps] = useState(0);

  // State to manage the visibility of the table dropdown
  const [tableIsOpen, setTableIsOpen] = useState(false);

  // State to manage the visibility of the time picker
  const [timeIsOpen, setTimeIsOpen] = useState(false);

  // State to manage map visibility
  const [mapVisible, setMapVisible] = useState(false);

  const location = useLocation();
  // console.log(location.state);
  const prevSummary = JSON.parse(sessionStorage.getItem("prevSummary"));
  // console.log(prevSummary);
  const summary = prevSummary?.club || location?.state?.club;

  if (!summary) {
    window.location.href = "/";
  }

  const userSummary = useSelector((state) => state.user.user);
  // console.log(userSummary);

  // collect date
  const [date, setDate] = useState("");

  const [tableTypes, setTableTypes] = useState([]);
  useEffect(() => {
    axiosInstance
      .get(`/tableType?establishmentId=${summary.id}`)
      .then((res) => {
        console.log(res.data.data);
        setTableTypes(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const passedFormData = sessionStorage.getItem("prevFormData");
  let initialFormData;

  try {
    initialFormData = passedFormData ? JSON.parse(passedFormData) : null;
  } catch (error) {
    console.error("Error parsing prevFormData:", error);
    initialFormData = null;
  }

  if (!initialFormData) {
    initialFormData = {
      establishmentId: summary?.id,
      paymentIntegratorId: summary?.id,
      reservationDate: date,
      orderItems: [
        {
          comboId: "",
          orderedTableId: "",
          lineItems: null,
        },
      ],
    };
  }

  const [formData, setFormData] = useState(initialFormData);
  const [price, setPrice] = useState("");

  const [response, setResponse] = useState("");
  const [alert, setAlert] = useState(false);
  const [bgcolor, setBgColor] = useState("");
  const [icon, setIcon] = useState("");

  // Check if user is logged in
  const token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (steps !== 1) {
      setSteps((step) => step + 1);
    } else if (token) {
      console.log(formData);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        key: `${process.env.REACT_APP_PAYSTACK}`,
        amount: price * 100,
        email: `${userSummary.email}`,
        firstname: `${userSummary.userName}`,
        lastname: "",
        onSuccess(transaction) {
          console.log(transaction);
          let message = `Payment Complete!!! Reference ${transaction.reference}`;
          setAlert(!alert);
          setBgColor("green");
          setResponse(message);
          setIcon(<BsPatchCheck />);
          axiosInstance
            .post("order", formData)
            .then((res) => {
              console.log(res);
              setAlert(true);
              setBgColor("green");
              setResponse(res.data.message);
              setIcon(<BsPatchCheck />);
            })
            .catch((err) => {
              console.log(err);
              setAlert(true);
              setResponse(err?.response.data.message);
              setBgColor("red");
              setIcon(<CiWarning />);
            });
        },
        onCancel() {
          setAlert(!alert);
          setResponse("Transaction cancelled");
          setBgColor("red");
          setIcon(<CiWarning />);
        },
      });
    } else {
      const formDataJSON = JSON.stringify(formData);
      sessionStorage.setItem("prevFormData", formDataJSON);
      sessionStorage.setItem("previousPage", window.location.href);
      sessionStorage.setItem("prevSummary", JSON.stringify(location?.state));
      navigate("/join");
    }
  };

  setTimeout(() => {
    if (alert === true) {
      setAlert(false);
    }
  }, 3000);

  // Handle Table Selection:
  const [selectedTable, setSelectedTable] = useState(null);
  const [drinkDropdownVisible, setDrinkDropdownVisible] = useState(false);
  const handleShowDrinks = () => {
    setDrinkDropdownVisible(!drinkDropdownVisible);
  };

  const orderIndex = 0; // Provide the appropriate index or default value
  const lineItemIndex = 0; // Provide the appropriate index or default value

  const handleRadioChange = (tableId) => {
    // Find the selected table by matching the tableId
    const selectedTableType = tableTypes.find((tableType) =>
      tableType.tables.some((table) => table.id === tableId)
    );

    if (selectedTableType) {
      const selectedTable = selectedTableType.tables.find(
        (table) => table.id === tableId
      );

      // Extract comboId from selectedTable
      const comboId = selectedTableType.drinkCombos[0].comboId;

      setFormData((prevData) => ({
        ...prevData,
        orderItems: [
          {
            orderedTableId: tableId,
            lineItems: prevData.orderItems[0].lineItems,
            comboId: comboId,
          },
        ],
        tableId,
      }));

      console.log("selected table", selectedTable);

      // Update the selected table state with the table's drinkOptions
      setSelectedTable(selectedTable.drinkOptions);
      setTimeout(() => {
        setTableIsOpen(false);
      }, 1000);
    }
  };

  const handleDateChange = (selectedDate) => {
    // Update the selected date state
    setDate(selectedDate);
    // Update the form data with the selected date
    setFormData({
      ...formData,
      reservationDate: format(new Date(selectedDate), "yyyy-MM-dd"),
    });
  };

  // Sections for Accordion Component
  const sections = tableTypes.map((tableType, index) => ({
    header: (
      <div
        className="space-y-3"
        onClick={() => {
          setPrice(tableType.price);
        }}
      >
        <div className="flex gap-3 items-center">
          <MdOutlineTableBar className="text-primary h-5 w-5" />
          <h6 className="font-semibold">{tableType.name}</h6>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex gap-3 items-center">
            <BsTicketPerforated className="text-primary -rotate-45 h-5 w-5" />
            <p>{`${tableType.price} Naira`}</p>
          </div>
          <div className="flex gap-3 items-center">
            <PiUsersLight className="text-primary h-5 w-5" />
            <p>{`${tableType.numberOfSeat} Seats Available`}</p>
          </div>
        </div>
      </div>
    ),
    content: (
      <div className="space-y-3">
        <button
          className="text-primary text-sm"
          onClick={() => setMapVisible(!mapVisible)}
        >
          See tables map
        </button>
        <div className="relative space-y-7 pb-5">
          <div className="">
            <Popover placement="bottom" className="w-fit">
              <PopoverHandler>
                <Input
                  label="Select a Date"
                  onChange={() =>
                    setFormData({ ...formData, reservationDate: date })
                  }
                  readOnly
                  value={date ? format(new Date(date), "yyyy-MM-dd") : ""}
                />
              </PopoverHandler>
              <PopoverContent>
                <DayPicker
                  mode="single"
                  selected={date}
                  onSelect={handleDateChange}
                  showOutsideDays
                  className="border-0"
                  classNames={{
                    caption:
                      "flex justify-center py-2 mb-4 relative items-center",
                    caption_label: "text-sm font-medium text-gray-900",
                    nav: "flex items-center",
                    nav_button:
                      "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                    nav_button_previous: "absolute left-1.5",
                    nav_button_next: "absolute right-1.5",
                    table: "w-full border-collapse",
                    head_row: "flex font-medium text-gray-900",
                    head_cell: "m-0.5 w-9 font-normal text-sm",
                    row: "flex w-full mt-2",
                    cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                    day: "h-9 w-9 p-0 font-normal",
                    day_range_end: "day-range-end",
                    day_selected:
                      "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                    day_today: "rounded-md bg-gray-200 text-gray-900",
                    day_outside:
                      "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                    day_disabled: "text-gray-500 opacity-50",
                    day_hidden: "invisible",
                  }}
                  components={{
                    IconLeft: ({ ...props }) => (
                      <FaCircleChevronLeft
                        {...props}
                        className="h-4 w-4 stroke-2 text-primary"
                      />
                    ),
                    IconRight: ({ ...props }) => (
                      <FaCircleChevronRight
                        {...props}
                        className="h-4 w-4 stroke-2 text-primary"
                      />
                    ),
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="relative">
            <Button
              className="border-primary border-2 text-primary outline-none bg-transparent w-full"
              onClick={() => setTableIsOpen(!tableIsOpen)}
            >
              Select Table
            </Button>
            {/* <Button
              className="border-primary border-2 text-primary outline-none bg-transparent w-full basis-1/2"
              onClick={handleShowDrinks}
            >
              Select Drinks
            </Button> */}
            {tableIsOpen && (
              <div
                className={`absolute w-full h-40 rounded-lg py-3 shadow-lg top-12 left-0 bg-white overflow-y-auto ${
                  tableIsOpen
                    ? "transition ease-out duration-200 opacity-100 scale-100 z-20"
                    : "transition ease-in duration-200 scale-90 z-20"
                }`}
              >
                <Tablepicker
                  setTableIsOpen={setTableIsOpen}
                  tables={tableType.tables}
                  formData={formData}
                  setFormData={setFormData}
                  onRadioChange={handleRadioChange}
                />
              </div>
            )}
            {/* {drinkDropdownVisible && (
              <div
                className={`absolute w-1/2 h-40 rounded-lg py-3 px-4 top-12 right-0 shadow-lg bg-white overflow-y-scroll ${
                  drinkDropdownVisible
                    ? "transition ease-out duration-200 opacity-100 scale-100"
                    : "transition ease-in duration-200 scale-90"
                }`}
              >
                <DrinkPicker
                  selectedTable={selectedTable}
                  setDrinkDropdownVisible={setDrinkDropdownVisible}
                  formData={formData}
                  setFormData={setFormData}
                  orderIndex={orderIndex}
                  lineItemIndex={lineItemIndex}
                  onDrinkSelect={handleShowDrinks}
                />
              </div>
            )} */}
          </div>
          <div>
            <ul>
              <li>Fast track entry</li>
              <li>Bar spend as per minimum spend included.</li>
              <li>Designated hostess service.</li>
              <li>Our service has zero cost on client side.</li>
            </ul>
          </div>
          {mapVisible && <img className="mx-auto" src={BarMap} alt="" />}
        </div>
      </div>
    ),
  }));

  const clubSummary = (
    <div className="grid gap-3">
      <div className="">
        <h4 className="font-bold">About</h4>
        <p className="text-justify">{summary?.description}</p>
      </div>
      <div className="grid gap-3">
        <h4 className="font-bold">Location</h4>
        <p className="text-justify">{`${summary?.address}, ${summary?.state}, Nigeria`}</p>
        <div className="h-48 rounded-lg">
          <Map
            height={192}
            defaultCenter={[summary?.longitude, summary?.latitude]}
            defaultZoom={10}
          >
            <Marker
              width={30}
              anchor={[summary?.longitude, summary?.latitude]}
            />
          </Map>
        </div>
      </div>
    </div>
  );

  const accordion = (
    <div className="mt-5">
      <h4 className="text-2xl font-bold">Choose Seat</h4>
      <Accordion
        sections={sections}
        tableIsOpen={tableIsOpen}
        setTableIsOpen={setTableIsOpen}
        mapVisible={mapVisible}
        setMapVisible={setMapVisible}
        timeIsOpen={timeIsOpen}
        setTimeIsOpen={setTimeIsOpen}
      />
    </div>
  );

  const activeStep = () => {
    if (steps === 0) {
      return clubSummary;
    } else {
      return accordion;
    }
  };

  // Handle type of event place
  const clubType = (clubNumber) => {
    if (clubNumber === 1) {
      return "Unknown";
    } else if (clubNumber === 2) {
      return "Club";
    } else if (clubNumber === 3) {
      return "Bar";
    } else {
      return "Lounge";
    }
  };

  PageTitle("Jaiye - Book Table");
  return (
    <div className="p-7 grid gap-5 min-h-screen items-start">
      <div className="grid gap-3 items-start pb-7">
        <NavBar title={"Book Table"} />
        <div className="max-w-7xl mx-auto">
          <div className="grid gap-14">
            <div>
              <h4 className="text-2xl font-bold">{summary?.name}</h4>
              <img
                className="w-full object-cover rounded-lg h-full"
                src={summary?.imageUrl}
                alt="business banner"
              />
            </div>
            <div className="grid gap-2">
              <div className="flex justify-between">
                <span className="flex">
                  <PiWineLight className="h-5 w-5 text-primary" />
                  <p>{clubType(summary?.establishmentType)}</p>
                </span>
                <span className="flex">
                  <TfiLocationArrow className="h-5 w-5 rotate-90 text-primary" />
                  <p>2.6Km</p>
                </span>
                <span className="flex">
                  <TfiStar className="h-5 w-5 text-primary" />
                  <p>4.1(64)</p>
                </span>
                <span className="flex">
                  <PiClockLight className="h-5 w-5 text-primary" />
                  <p>{summary?.openingTime}</p>
                  {/* <p>{TimeConverter(summary?.openingTime)}</p> */}
                </span>
              </div>
              <span className="flex">
                <HiPhone className="h-5 w-5 text-primary" />
                <p className="font-semibold">{summary?.contactPhone}</p>
              </span>
            </div>
          </div>
          {activeStep()}
        </div>
        {alert && (
          <Alert
            animate={{
              mount: { y: 0 },
              unmount: { y: 100 },
            }}
            color={bgcolor}
            icon={icon}
            className="absolute w-11/12 right-5 h-12 top-auto z-50"
          >
            {response}
          </Alert>
        )}
        <Button className="bg-primary mt-7 lg:mx-auto" onClick={handleSubmit}>
          {steps === 0 ? "Select Table" : "Book Table"} <span>&#8594;</span>
        </Button>
      </div>
    </div>
  );
};

export default Clubpage;
