import React from "react";
import Input from "../../components/Input";
import { BiCloudUpload } from "react-icons/bi";
import { PiPlusCircleFill } from "react-icons/pi";
import PrimaryButton from "../../components/PrimaryButton";

const TicketSalesForm = () => {
  return (
    <form action="" className="grid gap-5">
      <h5 className="text-xl font-semibold">Create Event</h5>
      <Input label={"Event Name"} type={"text"} id={"eventName"} />
      <fieldset className="grid gap-5">
        <h5 className="text-xl font-semibold">Event Dates</h5>
        <fieldset className="flex justify-between items-center\">
          <Input label={"From"} type={"date"} id={"startingDate"} />
          <Input label={"To"} type={"date"} id={"endDate"} />
        </fieldset>
      </fieldset>
      <div className="flex items-center justify-center w-full border-2 border-dashed rounded-lg cursor-pointer">
        <label htmlFor="fileUpload">
          <div className="flex flex-col items-center justify-center py-10">
            <p>Display Picture</p>
            <BiCloudUpload />
            <p>
              Drag and Drop or <span className="text-primary">Browse</span> to
              upload
            </p>
            <input
              type="file"
              name="fileUpload"
              multiple
              id="fileUpload"
              className="sr-only"
            />
          </div>
        </label>
      </div>
      <fieldset>
        <div className="flex justify-between">
          <h5 className="text-xl font-semibold">Ticket Type</h5>
          <h5 className="text-xl font-semibold flex text-primary items-center gap-3">
            <PiPlusCircleFill />
            Add Ticket Type
          </h5>
        </div>
      </fieldset>
      <fieldset className="grid gap-5">
        <Input label={"Ticket Name"} type={"text"} id={"ticketName"} />
        <Input
          label={"Quantity of Guest(s) Allowed"}
          type={"number"}
          id={"guestsAllowed"}
        />
        <Input label={"Ticket Price"} type={"number"} id={"ticketPrice"} />
        <Input label={"Ticket Details"} type={"text"} id={"tecketDetails"} />
      </fieldset>
      <PrimaryButton text={"Submit"} />
    </form>
  );
};

export default TicketSalesForm;
