// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Membership from "./Pages/Membership";
import Explore from "./Pages/Explore";
import Clubpage from "./Pages/Clubpage";
import Ticket from "./Pages/Ticket";
import QRCode from "./Pages/QRCode";
import History from "./Pages/History";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import EventPage from "./Pages/EventPage";
import AllEvents from "./Pages/AllEvents";
import CocoFest from "./Pages/CocoFest";
import PurchaseSummary from "./Pages/PurchaseSummary";
import GenZ from "./Pages/GenZ";
import GenZEventPage from "./Pages/GenZEventPage";
import AdminProfile from "./Pages/adminPages/Profile";
// import AdminProfileUpdate from "./Pages/adminPages/ProfileUpdateForm";
// import ProfileForm from "./Pages/adminPages/Profile";
import Staff from "./Pages/adminPages/Staff";
import Reservations from "./Pages/adminPages/Reservations";
import SuperAdminLogin from "./Pages/membershipForms/SuperAdminLogin";
import { selectIsAuthenticated } from "./utils/app/userSlice";

function App() {
  // Access the isAuthenticated state from Redux store
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <div className="bg-[#F9F9F9] text-tertiary min-h-screen overflow-y-scroll md:overflow-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="join" element={<Membership />} />
          <Route path="adminLogin" element={<SuperAdminLogin />} />
          <Route
            path="adminProfileForm"
            element={isAuthenticated ? <AdminProfile /> : <Membership />}
          />
          <Route
            path="staff"
            element={isAuthenticated ? <Staff /> : <Membership />}
          />
          <Route
            path="reservations"
            element={isAuthenticated ? <Reservations /> : <Membership />}
          />
          <Route path="/" element={<Explore />} />
          <Route path="cocoFest" element={<CocoFest />} />
          <Route path="genZ" element={<GenZ />} />
          <Route path="genZEventSummary" element={<GenZEventPage />} />
          <Route
            path="events"
            element={<EventPage />}
            // element={isAuthenticated ? <EventPage /> : <Membership />}
          />

          {/* Protected routes */}
          <Route
            path="dashboard"
            element={isAuthenticated ? <Home /> : <Membership />}
          />
          <Route path="summary" element={<PurchaseSummary />} />
          <Route
            path="club"
            element={<Clubpage />}
            // element={isAuthenticated ? <Clubpage /> : <Membership />}
          />
          <Route
            path="ticket"
            element={isAuthenticated ? <Ticket /> : <Membership />}
          />
          <Route
            path="event-booking"
            element={isAuthenticated ? <AllEvents /> : <Membership />}
          />
          <Route
            path="scan"
            element={isAuthenticated ? <QRCode /> : <Membership />}
          />
          <Route
            path="history"
            element={isAuthenticated ? <History /> : <Membership />}
          />
          <Route
            path="profile"
            element={isAuthenticated ? <Profile /> : <Membership />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
