import React, { useState } from "react";
import { FaCircleChevronDown } from "react-icons/fa6";

const Accordion = ({
  formData,
  setFormData,
  sections,
  tableIsOpen,
  setTableIsOpen,
  timeIsOpen,
  setTimeIsOpen,
  mapVisible,
  setMapVisible,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    if (tableIsOpen === true) {
      setTableIsOpen(!tableIsOpen);
    }
    if (timeIsOpen === true) {
      setTimeIsOpen(!timeIsOpen);
    }
    if (mapVisible === true) {
      setMapVisible(!mapVisible);
    }
  };

  return (
    <div className="">
      {sections.map((section, index) => (
        <div className="border-b p-2 py-5 flex justify-between" key={index}>
          <div className="grid w-full gap-3">
            <div
              className={`cursor-pointer accordion-header ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => handleAccordionClick(index)}
            >
              {section.header}
            </div>
            {activeIndex === index && (
              <div className="accordion-content">{section.content}</div>
            )}
          </div>
          <FaCircleChevronDown className="text-primary" />
        </div>
      ))}
    </div>
  );
};

export default Accordion;
