import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import Avatar from "../../images/Avatar.svg";
import { useSelector } from "react-redux";
// import ProfileForm from "./ProfileForm";
import ProfileUpdateForm from "./ProfileUpdateForm";

const UserProfile = () => {
  const user = useSelector((state) => state?.user?.user);

  const [formVisible, setFormVisible] = useState(false);

  return (
    <>
      {!formVisible && (
        <div className="space-y-10 basis-2/3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-5">
              <img
                src={Avatar}
                className="h-14 bg-primary/10 rounded-full"
                alt=""
              />
              <div>
                <h3 className="text-lg font-semibold">Olu Ishola</h3>
                <p className="font-semibold">Security Personnel</p>
              </div>
            </div>
            <CiEdit
              className="text-primary h-6 w-6 cursor-pointer"
              onClick={() => setFormVisible((prevVisible) => !prevVisible)} // This handles the toggle to show user profile form
            />
          </div>
          <div className="space-y-10">
            <h3 className="font-semibold">Personal Information</h3>
            <div className="grid gap-5 md:flex md:justify-between">
              <div className="space-y-1">
                <p className="text-[#848484]">Email Address</p>
                <p>{user?.email}</p>
              </div>
              <div className="space-y-1">
                <p className="text-[#848484]">Gender</p>
                <p>Female</p>
              </div>
            </div>
            <div className="grid gap-5 md:flex md:justify-between">
              <div className="space-y-1">
                <p className="text-[#848484]">Phone Number</p>
                <p>{user?.phoneNumber}</p>
              </div>
              <div className="space-y-1">
                <p className="text-[#848484]">Date of Birth</p>
                <p>30/10/1986</p>
              </div>
            </div>
            <div className="grid gap-5 md:flex md:justify-between">
              <div className="space-y-1">
                <p className="text-[#848484]">Street Address</p>
                <p>12, Ajanlekoko street off Omilani Street</p>
              </div>
              <div className="space-y-1">
                <p className="text-[#848484]">City</p>
                <p>Ikeja</p>
              </div>
            </div>
            <div className="grid gap-5 md:flex md:justify-between">
              <div className="space-y-1">
                <p className="text-[#848484]">State</p>
                <p>Lagos State</p>
              </div>
              <div className="space-y-1">
                <p className="text-[#848484]">Country</p>
                <p>Nigeria</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {formVisible && <ProfileUpdateForm />}
    </>
  );
};

export default UserProfile;
