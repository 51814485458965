import React, { useState, useEffect } from "react";
import { Alert, Spinner } from "@material-tailwind/react";
import { BsPatchCheck } from "react-icons/bs";
import { CiWarning } from "react-icons/ci";
import PrimaryButton from "./PrimaryButton";
import { useZxing } from "react-zxing";
import axiosInstance from "../utils/axios/axios";

// ... (previous imports)

const QRScannerComponent = () => {
  const [result, setResult] = useState("");
  const [alert, setAlert] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [icon, setIcon] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticketSummary, setTicketSummary] = useState({});

  const handleDecodeResult = (decodedResult) => {
    setResult(decodedResult.getText());
  };

  const handleError = (error) => {
    console.error("Error scanning QR code:", error);
  };

  useEffect(() => {
    let data = {};
    if (result) {
      try {
        // Parse the JSON data from the QR code result
        const parsedData = JSON.parse(result);
        // console.log(parsedData);

        // Add your logic to handle the parsed data, e.g., make an API call
        setLoading(true);
        const ticketId = parsedData.ticketId;
        data = {
          numberOfPasses: 1,
          ticketId: ticketId,
        };

        axiosInstance
          .post("/events/ticket/validate", data)
          .then((res) => {
            axiosInstance
              .get(`Events/ticket/${ticketId}`)
              .then((res) => {
                // console.log(res);
                setAlert(true);
                setBgColor("green");
                setIcon(<BsPatchCheck />);
                setResponse(res.data.message);
                setTicketSummary(res.data);
              })
              .catch((err) => {
                // console.log(err);
              });
          })
          .catch((err) => {
            setAlert(true);
            setBgColor("red");
            setIcon(<CiWarning />);
            setResponse(err.response?.data?.message || err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setAlert(true);
        setBgColor("red");
        setIcon(<CiWarning />);
        setResponse("Error parsing QR code data");
      }
    }
  }, [result]);

  const { ref } = useZxing({
    onDecodeResult: handleDecodeResult,
    onError: handleError,
  });

  setTimeout(() => {
    if (alert) {
      setAlert(false);
    }
  }, 10000);

  return (
    <div className="grid">
      {alert && (
        <Alert
          animate={{
            mount: { y: 0 },
            unmount: { y: 0 },
          }}
          color={bgColor}
          icon={icon}
          className="absolute h-auto top-8 w-11/12 right-5 z-50"
        >
          {response}
        </Alert>
      )}
      <div className="border-s border-e grid border-primary m-5 p-5 rounded-xl">
        {loading ? (
          <Spinner color="pink" className="mx-auto" />
        ) : (
          <div className="space-y-3">
            <div className="grid gap-5">
              <video ref={ref} />
              <PrimaryButton
                text={"SCan Again"}
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
            {alert && (
              <div>
                <p className="font-semibold">
                  Event Name:{" "}
                  <span className="text-primary">
                    {ticketSummary?.data?.eventName}
                  </span>
                </p>
                <p className="font-semibold">
                  Event Venue:{" "}
                  <span className="text-primary">
                    {ticketSummary?.data?.eventVenue}
                  </span>
                </p>
                <p className="font-semibold">
                  Ticket Type:{" "}
                  <span className="text-primary">
                    {ticketSummary?.data?.ticketTypeName}
                  </span>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QRScannerComponent;
