import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Spinner,
  Step,
  Stepper,
} from "@material-tailwind/react";
import BasicInformation from "./BasicInformation";
import TableInformation from "./TableInformation";
import { useSelector } from "react-redux";
import { PiPlusCircleFill } from "react-icons/pi";
import axiosInstance from "../../utils/axios/axios";
import { BsPatchCheck } from "react-icons/bs";
import { CiWarning } from "react-icons/ci";

const ProfileForm = () => {
  const user = useSelector((state) => state.user);
  const [activeForm, setActiveForm] = useState(0);
  const [formData, setFormData] = useState({
    establishmentDetails: {
      name: "",
      openingTime: "",
      closingTime: "",
      establishmentType: "",
      longitude: 0,
      latitude: 0,
      address: "",
      state: "Lagos",
      contactPhone: "",
      description: "",
      orderType: 1,
      shortCode: "",
      establishmentGroupId: null,
      base64Images: [],
    },
    tables: [
      {
        name: "",
        numberOfSeat: 0,
        price: {},
        description: "",
        numberOfTables: 0,
      },
    ],
  });
  const [visibleTable, setVisibleTable] = useState([]);
  const [alert, setAlert] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [icon, setIcon] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  // Initialize a single instance of TableInformation on mount
  useEffect(() => {
    setVisibleTable([<TableInformation key={Date.now()} />]);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      establishmentDetails: {
        ...formData.establishmentDetails,
        [e.target.name]: e.target.value,
      },
    });
  };

  // const handleSubmit = () => {
  //   if (activeForm === 0) {
  //     setActiveForm((step) => step + 1);
  //   } else {
  //     const cleanedBase64Images =
  //       formData.establishmentDetails.base64Images.map((image) => {
  //         const prefixIndex = image.indexOf(",") + 1;
  //         return image.substring(prefixIndex);
  //       });

  //     // Convert string value to number
  //     const establishmentTypeNumber = parseInt(
  //       formData.establishmentDetails.establishmentType
  //     );

  //     // Update formData with the converted value
  //     const updatedFormData = {
  //       ...formData,
  //       establishmentDetails: {
  //         ...formData.establishmentDetails,
  //         establishmentType: establishmentTypeNumber,
  //       },
  //     };

  //     // Log updated formData
  //     logFormData(updatedFormData);

  //     setLoading((oldLoad) => !oldLoad);

  //     axiosInstance
  //       .post("/establishment/onboard", updatedFormData)
  //       .then((res) => {
  //         console.log(res);
  //         setAlert((oldValue) => !oldValue);
  //         setBgColor("green");
  //         setIcon(<BsPatchCheck />);
  //         setLoading((oldStatus) => !oldStatus);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setAlert((oldAlert) => !oldAlert);
  //         setBgColor("red");
  //         setIcon(<CiWarning />);
  //         setResponse(err.response.data.title);
  //         setLoading((oldStatus) => !oldStatus);
  //       });
  //   }
  // };

  const handleSubmit = () => {
    if (activeForm === 0) {
      setActiveForm((step) => step + 1);
    } else {
      // Remove "data:image/jpeg;base64," prefix from each base64 image
      const cleanedBase64Images =
        formData.establishmentDetails.base64Images.map((image) => {
          const prefixIndex = image.indexOf(",") + 1;
          return image.substring(prefixIndex);
        });

      // Convert string value to number
      const establishmentTypeNumber = parseInt(
        formData.establishmentDetails.establishmentType
      );

      // Create a copy of the formData object
      const updatedFormData = {
        ...formData,
        establishmentDetails: {
          ...formData.establishmentDetails,
          establishmentType: establishmentTypeNumber,
          base64Images: cleanedBase64Images, // Update base64Images with cleaned images
        },
      };

      // Log updated formData
      logFormData(updatedFormData);

      setLoading((oldLoad) => !oldLoad);

      axiosInstance
        .post("/establishment/onboard", updatedFormData)
        .then((res) => {
          console.log(res);
          setAlert((oldValue) => !oldValue);
          setBgColor("green");
          setIcon(<BsPatchCheck />);
          setResponse(res.data.message);
          setLoading((oldStatus) => !oldStatus);
        })
        .catch((err) => {
          console.log(err);
          setAlert((oldAlert) => !oldAlert);
          setBgColor("red");
          setIcon(<CiWarning />);
          setResponse(err.response.data.title);
          setLoading((oldStatus) => !oldStatus);
        });
    }
  };

  // Function to log formData
  const logFormData = (formDataToLog) => {
    console.log("Form Data Before API Request:", formDataToLog);
  };

  setTimeout(() => {
    if (alert === true) {
      setAlert(false);
    }
  }, 3000);

  const addTable = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tables: [
        ...prevFormData.tables,
        {
          name: "",
          numberOfSeat: 0,
          price: {},
          description: "",
          numberOfTables: 0,
        },
      ],
    }));
  };

  return (
    <div className="grid gap-5 w-full">
      <Stepper lineClassName="bg-primary/20" activeStep={activeForm}>
        <Step color={activeForm === 0 ? "blue-gray" : "red"}>1</Step>
        <Step>2</Step>
      </Stepper>
      {alert && (
        <Alert
          animate={{
            mount: { y: 0 },
            unmount: { y: 0 },
          }}
          color={bgColor}
          icon={icon}
          className="absolute h-auto top-8 w-11/12 right-5 z-50"
        >
          {response}
        </Alert>
      )}
      <div className="flex justify-between">
        <h3 className="font-semibold text-lg">
          {activeForm === 0 ? "Basic Information" : "Set up Table"}
        </h3>
        {activeForm !== 0 && (
          <h5
            className="text-xl cursor-pointer font-semibold flex text-primary items-center gap-3"
            onClick={addTable}
          >
            <PiPlusCircleFill />
            Add Table
          </h5>
        )}
      </div>
      <form action="" className="grid gap-5 w-full">
        {activeForm === 0 ? (
          <BasicInformation
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />
        ) : (
          visibleTable.map((table, index) => (
            <TableInformation
              key={index}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
            />
          ))
        )}
        {loading ? (
          <Spinner color="pink" className="mx-auto" />
        ) : (
          <ButtonGroup size="lg" fullWidth color="red">
            <Button
              disabled={activeForm === 0}
              onClick={() => setActiveForm((step) => step - 1)}
            >
              Prev
            </Button>
            <Button onClick={handleSubmit}>
              {activeForm === 0 ? "Next" : "Submit"}
            </Button>
          </ButtonGroup>
        )}
      </form>
    </div>
  );
};

export default ProfileForm;
